<template>
  <BasicFormWrapper>
    <ReportFilterWrapper>
      <a-form name="multi-form" layout="vertical" class="products-search-filter" @keyup.enter.prevent="applyFilters">
        <!-- <a-row :gutter="30">
          <a-col :lg="24" :sm="24" :xs="24">
            <FilterDiv>
              <span v-if="isShow && filterOptions && Object.keys(filterOptions).length > 0" class="title"
                >Active Filters:</span
              >
              <span v-for="(key, index) in Object.keys(filterOptions)" :key="index">
                <span class="m-20" v-if="filterOptions[key].value">
                  <span>{{ `${key}: ${filterOptions[key].value}` }}</span>
                  <i class="fa fa-times" @click="removeFilter(key)" />
                </span>
              </span>
            </FilterDiv>
          </a-col>
        </a-row> -->
        <!-- <a-row :gutter="30">
          <a-col :lg="6" :sm="12" :xs="24">
            <a-form-item label="Status">
              <a-select v-model:value="formState.status" :style="{ height: '32px !important' }">
                <a-select-option :value="1">Enabled</a-select-option>
                <a-select-option :value="2">Disabled</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row> -->
        <a-row :gutter="20" class="mb-20">
          <a-col :xs="24" :md="12">
            <a-row>
              <a-col :xs="24">
                <sdCards title="Reg Reporting">
                  <a-select @change="changeClient" :value="formState.clientId" :style="{ height: '32px !important' }">
                    <a-select-option :value="0">All</a-select-option>
                    <a-select-option :value="client.id" v-for="client in clients" :key="client.id">{{
                      client.name
                    }}</a-select-option>
                  </a-select>
                </sdCards>
              </a-col>
              <a-col :md="12" :xs="24" class="mb-25" :style="{ marginTop: '15px' }">
                <sdCards title="Storeviews">
                  <a-radio-group v-model:value="formState.storeview">
                    <a-radio-button :value="storeview" v-for="(storeview, index) in storeviews" :key="index">{{
                      storeview.code
                    }}</a-radio-button>
                  </a-radio-group>
                </sdCards>
              </a-col>
            </a-row>
          </a-col>

          <a-col :md="12" :xs="24">
            <sdCards title="Preset Ranges ">
              <DatePickerWrapper>
                <a-range-picker
                  :ranges="{
                    Today: [moment(), moment()],
                    'This Month': [moment(), moment().endOf('month')],
                  }"
                  @change="onChange"
                />
              </DatePickerWrapper>
            </sdCards>
          </a-col>
        </a-row>
        <a-row justify="end" :gutter="8">
          <a-col flex>
            <sdButton type="primary" :raised="true" @click="search">
              {{ 'Search' }}
            </sdButton>
            <sdButton type="primary" :style="{ marginLeft: '32px !important' }" :raised="true" :disable="true">
              {{ 'Clear Filters' }}
            </sdButton>
          </a-col>
        </a-row>
      </a-form>
    </ReportFilterWrapper>
  </BasicFormWrapper>
</template>

<script>
import { BasicFormWrapper, DatePickerWrapper } from '../../styled';
import { ReportFilterWrapper } from './style';
import { ref, onMounted, computed } from 'vue';
import moment from 'moment';
import { useStore } from 'vuex';

export default {
  name: 'ProductFilters',
  components: { BasicFormWrapper, ReportFilterWrapper, DatePickerWrapper },
  setup() {
    const { state, dispatch, commit } = useStore();
    const form = computed(() => state.reporting.formState);
    const formState = ref(form);
    const storeviews = computed(() => state.reporting.storeviews);
    const clients = computed(() => state.clients.clients);

    const onChange = (date, dateString) => {
      console.log(date, 'date');
      commit('setDateRange', dateString);
    };

    const changeClient = async (value) => {
      console.log(value, 'clientId');
      await commit('setReportClientId', value);
      await dispatch('fetchStoreviewDataForReport', value);
    };

    const search = async () => {
      await commit('setReportFilter', form.value);
      await dispatch('fetchReportData');
    };

    onMounted(async () => {
      await dispatch('fetchClients');
      await dispatch('fetchReportData');
    });

    return {
      formState,
      moment,
      onChange,
      storeviews,
      clients,
      search,
      changeClient,
    };
  },
};
</script>

<template>
  <CardToolbox>
    <sdPageHeader title="Registration Lookup Report" />
  </CardToolbox>

  <ReportFilter />

  <Main>
    <a-row :gutter="15">
      <a-col :xs="24">
        <div v-for="(data, index) in reportData" :key="index">
          {{ data.key + ':' + data.doc_count }}
        </div>
      </a-col>
    </a-row>
  </Main>
</template>

<script>
import { Main, CardToolbox } from '@/view/styled';
import ReportFilter from './ReportFilter.vue';
import { computed } from 'vue';
import { useStore } from 'vuex';

export default {
  name: 'UserLogs',
  components: {
    Main,
    CardToolbox,
    ReportFilter,
  },
  setup() {
    const { state } = useStore();
    const reportData = computed(() => state.reporting.reportData);

    return {
      reportData,
    };
  },
};
</script>

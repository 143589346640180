<template>
  <CardToolbox>
    <sdPageHeader title="User Logs" />
  </CardToolbox>

  <Main>
    <a-row :gutter="15">
      <a-col :xs="24">
        <TableStyleWrapper>
          <TableWrapper class="table-responsive">
            <a-table
              :dataSource="tableData"
              :columns="tableColumns"
              :loading="isLoading"
              :pagination="{ defaultPageSize: 10 }"
            />
          </TableWrapper>
        </TableStyleWrapper>
      </a-col>
    </a-row>
  </Main>
</template>

<script>
import { Main, CardToolbox, TableWrapper } from '@/view/styled';
import { TableStyleWrapper } from './style';
import { DataService } from '@/config/dataService/dataService';

const tableColumns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'E-mail',
    dataIndex: 'email',
    key: 'email',
  },
  {
    title: 'Company Name',
    dataIndex: 'company_name',
    key: 'company_name',
  },
  {
    title: 'Last Login Date',
    dataIndex: 'last_login',
    key: 'last_login',
  },
  {
    title: 'Last Login IP',
    dataIndex: 'last_login_ip',
    key: 'last_login_ip',
  },
  {
    title: 'Login Counter',
    dataIndex: 'login_counter',
    key: 'login_counter',
    align: 'center',
  },
];

export default {
  name: 'UserLogs',
  components: {
    Main,
    CardToolbox,
    TableWrapper,
    TableStyleWrapper,
  },
  data() {
    return {
      isLoading: false,
      tableData: [],
      tableColumns,
    };
  },
  async mounted() {
    const {
      data: { result, status },
    } = await DataService.get('users/logs');

    if (status === 'success') {
      this.tableData = result.map((r, index) => {
        const last_login_ip = r.last_login_ip && r.last_login_ip.includes('::1') ? 'localhost' : r.last_login_ip;
        return {
          ...r,
          key: r.id,
          id: index + 1,
          last_login_ip,
        };
      });
    }

    this.isLoading = false;
  },
};
</script>
